.react-calendar {
  padding: 20px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 16px;
  width: 100%;
  margin-top: 50px;
}

@media (min-width: 1024px) {
  .react-calendar {
    width: 550px;
  }
}

.react-calendar__navigation {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 7px;
  display: flex;
  height: 40px;
  align-items: center;
}

.react-calendar__navigation button {
  border: none;
  outline: none;
  background: none;
  color: white;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  cursor: pointer;
  white-space: nowrap;
}

.react-calendar__tile {
  height: 48px;
  width: 48px;
  border: none;
  outline: none;
  background: none;
  color: white;
  cursor: pointer;
}

.react-calendar__tile:disabled {
  opacity: 0.3;
  cursor: not-allowed;
}

.react-calendar__tile--active {
  background: #585ff6;
}
.react-calendar__tile--rangeStart {
  border-top-left-radius: 18px;
  border-bottom-left-radius: 18px;
}
.react-calendar__tile--rangeEnd {
  border-top-right-radius: 18px;
  border-bottom-right-radius: 18px;
}

.react-calendar__month-view__weekdays__weekday {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  width: 48px;
  text-decoration: none;
}
abbr[title] {
  text-decoration: none;
}
