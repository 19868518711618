.root {
  font-style: normal;
  font-weight: 600;
  font-size: 21px;
  line-height: 125%;
}

@media (min-width: 1024px) {
  .root {
    font-size: 32px;
  }
}