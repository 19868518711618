.root {
  width: 100%;
}

.root th {
  text-align: left;
}

.root td,
.root th {
  min-height: 100px;
  border-bottom: 1px solid #4c4c4c;
  padding: 30px 5px
}

.borderTop {
  border-top: 1px solid #4c4c4c;
}