.root {
  display: none;
  position: fixed;
  width: 100%;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 100;
  background: white;
  color: black;
  padding: 140px 64px 64px;
  overflow: scroll;
}

@media (min-width: 1024px) {
  .root {
    width: 60%;
  }
}

.open {
  display: block;
}

.root ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.root li {
  margin: 0;
  padding: 0;
  margin-bottom: 40px;
  display: block;
}

.root a {
  text-decoration: none;
  color: inherit;
}

.button {
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.3;
  border: 1px solid #ffffff;
  border-radius: 13px;
  cursor: pointer;
  padding: 4%;
  position: relative;
  z-index: 300;
  mix-blend-mode: difference;
}

@media (min-width: 1024px) {
  .button {
    width: 56px;
    height: 56px;
  }
}
