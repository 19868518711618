.root {
  display: flex;
  width: 100%;
  margin-top: 50px;
  margin-bottom: 50px;
  flex-direction: column;
}

.root > * {
  border-left: 1px solid #E7E7E7;
  border-top: 1px solid #E7E7E7;
  padding: 20px;
}

.root > *:first-child {
  border-top: none;
}


.root > *:last-child {
  border-right: none;
}

.link {
  display: flex;
  gap: 16px;
  align-items: center;
  font-weight: 500;
  font-size: 21px;
  line-height: 120%;
  height: 65px;
}

.buttons {
  margin-top: 40px;
  display: flex;
  gap: 15px;
}


@media (min-width: 1024px) {
  .root {
    margin-top: 90px;
    flex-direction: row;
    border-bottom: 1px solid #E7E7E7;
    border-top: 1px solid #E7E7E7;
  }
  .root > * {
    flex-basis: calc(100% / 3);
    padding: 30px;
    border-right: 1px solid #E7E7E7;

    border-left: none;
    border-top: none;
  }

  .root > *:first-child {
    padding-left: 0;
  }

  .buttons {
    margin-top: 70px;

  }
}
