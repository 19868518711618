.root {
  font-style: normal;
  font-weight: 600;
  font-size: 42px;
  line-height: 110%;
}

@media (min-width: 1024px) {
  .root {
    font-size: 62px;
  }
}
