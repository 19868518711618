.root {
  animation: spin 2s linear infinite;
  position: absolute;
  top: 50%;
  left: 50%;
}

@keyframes spin {
  from {
    transform: translate3d(-50%, -50%, 0) rotate(0);
  }
  to {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}
