.list {
  margin: 0 0 50px 20px;
  padding: 0;
}

.list li {
  color: white;
  padding-left: 65px;
  margin-bottom: 32px;
}

.list li:last-child {
  margin-bottom: 0;
}

.list li::marker {
  color: #4c4c4c;
}

.left {
  height: 100%;
  display: flex;
  padding-bottom: 50px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.cell {
  display: flex;
  align-items: center;
  gap: 10px;
}

@media (min-width: 1024px) {
  .list {
    margin-bottom: 150px;
    margin-left: -100px;
  }
  .list li {
    padding-left: 80px;
  }
  .left {
    padding-bottom: 150px;
  }
}
