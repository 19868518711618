.input {
  background: transparent;
  min-height: 70px;
  width: 100%;
  border: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  color: rgba(255, 255, 255, 1);
  font-style: normal;
  font-weight: 500;
  font-size: 23px;
  line-height: 120%;
  margin: 30px 0;
  display: block;
}

.input:focus {
  outline: none;
}

.large {
  font-size: 95px;
}

.flex {
  display: flex;
  align-items: flex-end;
  gap: 15px;
  /*width: 100%;*/
  margin-top: 10px;
}

.flex .large {
  flex-grow: 1;
}
.flex .input {
  margin: 0;
}
select.input {
  width: 200px;
}

.select {
  height: 56px;
  color: rgba(255, 255, 255, 0.3);
  background: transparent !important;
  border: 1px solid rgba(255, 255, 255, 0.3) !important;
  border-radius: 11px !important;
  font-style: normal;
  font-weight: 500;
  font-size: 23px;
  line-height: 120%;
  background: transparent;
  display: flex;
  min-width: 35%;
  padding: 0 10px;
  flex-wrap: nowrap !important;
}


.plusMinus {
  font-style: normal;
  font-weight: 400;
  font-size: 34px;
  line-height: 1.6;
}
.menu {
  border-radius: 15px !important;
}

.menuList {
  background: black;
  color: white;
}

.valueContainer {
  min-width: 200px;
}
.indicatorSeparator {
  display: none;
}
.option:hover {
  color: black;
  background: white;
}
.container {
  width: 100%;
}

.flex svg {
}

@media (min-width: 1024px) {
  .select {
    padding: 0 20px;
    height: 70px;
  }
  .flex {
    gap: 30px;
  }
  .plusMinus {
    line-height: 2.2;
  }
}