.root {
  width: 100%;
  color: #4c4c4c;
  position: relative;
}

.container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0;
  border-top: 1px solid #4c4c4c;
}
