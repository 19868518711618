.root {
  padding: 10px 0;
  position: fixed;
  width: 100vw;
  z-index: 200;
  background: rgba(0, 0, 0, 0.7);
  top: 0;
}

.container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.container a svg,
.button {
  mix-blend-mode: difference;
}
.container a svg:last-child {
  display: none;
}
@media (min-width: 1024px) {
  .root {
    padding: 15px 0;
  }
  .container a svg:last-child {
    display: block;
  }
  .container a svg:first-child {
    display: none;
  }
}
.right {
  display: flex;
  gap: 15px;
  align-items: center;
  flex-direction: row-reverse;
}

.button {
  position: relative;
  z-index: 300;
}
