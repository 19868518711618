.root {
  font-size: 14px;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0;
  opacity: 0.4;
}

.white {
  opacity: 1;
}

@media (min-width: 1024px) {
  .root {
    font-size: 18px;
  }
}