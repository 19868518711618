.root {
  border-top: 1px solid #4c4c4c;
  margin-top: 50px;
  padding-top: 20px;
  width: 100%;
}

.noPadding {
  padding-top: 0;
}

@media (min-width: 1024px) {
  .root {
    padding-top: 50px;
  }
}
