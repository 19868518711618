.root {
  font-size: 42px;
  line-height: 110%;
  font-weight: 600;
  letter-spacing: 0;
}

.withRight {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

@media (min-width: 1024px) {
  .root {
    font-size: 95px;
  }
}
