.root {
  position: relative;
  display: flex;
  margin: 0 auto;
  max-width: 1440px;
  width: 100%;
  padding: 0 20px;
  flex-wrap: wrap;
}

.root .root {
  padding: 0;
}

@media (min-width: 1024px) {
  .root {
    flex-wrap: nowrap;
    padding: 0 50px;
    gap: 20px;
  }
}
