.root {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.root > * {
  flex-basis: calc(100% / 3);
  margin-bottom: 10px;
}

@media (min-width: 1024px) {
  .root {
    flex-direction: row;
  }

  .root >* {
    margin-right: 20px;
  }
}
.root > *:not(:last-child) {
  padding-right: 0;
}
