.root {
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  min-height: 600px;
  background-size: cover;
}

@media (min-width: 1024px) {
  .root {
    min-height: 100vh;
  }
}

.center {
  align-items: center;
}

.eventually {
  justify-content: space-evenly;
}

.middle {
  justify-content: center;
}

.white {
  background: white;
  color: black;
}

.fixed {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 300;
}