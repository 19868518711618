.root {
  display: block;
  margin: 0;
  width: 100%;
  transition: width 0.5s ease-in-out, opacity 0.3s 0.3s ease-in-out;
}

.shrink {
  opacity: 0;
}

@media (min-width: 1024px) {
  .half {
    width: 50%;
  }
}
