.root {
  border: 1px solid #000000;
  border-radius: 13px;
  height: 55px;
  font-style: normal;
  font-weight: 500;
  font-size: 23px;
  line-height: 120%;
  width: 100%;
  padding: 0 20px;
  background: transparent;
  min-width: 100px;
  font-family: inherit;
}

@media (min-width: 1024px) {
  .root {
    height: 70px;
  }
}

.invert {
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.3);
}
