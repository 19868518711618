.root {
  display: flex;
  width: 100%;
}
.icon {
  flex-shrink: 0;
  width: 20%;
}
.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.title {
  margin-bottom: 20px;
}


@media (min-width: 1024px) {

  .title {
    margin-bottom: 35px;
  }

}