@font-face {
  font-family: "SFProDisplay";
  src: url("./SFProDisplay-Regular.ttf");
}
body {
  background: black;
  color: white;
  font-family: "SFProDisplay", sans-serif;
  -webkit-font-smoothing: antialiased;
}

* {
  box-sizing: border-box;
}
