.root {
  display: flex;
  list-style: none;
  margin: 50px 0 30px;
  padding: 0;
}

.root li:not(:last-child):after {
  content: "/";
  display: inline-flex;
  margin: 0 5px;
}

.root a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}
