.root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid #ffffff;
  border-radius: 20px;
  margin-bottom: 20px;
  padding: 15px;
  position: relative;
}

@media (min-width: 1024px) {
  .root {
    padding: 24px;
  }
}

.title {
  width: 100%;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.titleRight {
  justify-content: flex-end;
}

.content {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  position: relative;
  gap: 50px
}

.inner {
  width: 100%;
}

@media (min-width: 1024px) {
  .inner {
    display: flex;
    padding-bottom: 20px;
  }
  .inner > * {
    width: 50%;
  }

  .inner > *:not(.inner) {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
}

@media (max-width: 1023px) {
  .content > .inner {
    margin-top: 60px;
  }

  .inner > *:not(:last-child) {
    margin-bottom: 20px;
  }
}
.inner .inner {
  padding-bottom: 0;
}

.title a {
  color: inherit;
}
